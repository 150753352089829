export const isAuthenticate = () => {
  return Boolean(localStorage.getItem("JWT"));
};

export const getUserDetails = () => {
  const userDetails = localStorage.getItem("userDetails");

  // Check if userDetails exists and is valid JSON before parsing
  try {
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  } catch (error) {
    console.error("Error parsing userDetails JSON:", error);
    return null;
  }

  return null; // Return null if no userDetails or parsing error
};

export const setUserDetails = (userDetails) => {
  localStorage.setItem("userDetails", JSON.stringify(userDetails));
};

export const setJWT = (userJWT) => {
  localStorage.setItem("JWT", userJWT);
};

export const gmtToLocalTime = (datetime) => {
  const time = datetime + " GMT";
  const myDate = new Date(time);

  // Subtracting hours to account for timezone difference
  myDate.setHours(myDate.getHours() - 1);

  console.table({
    time,
    local: myDate.toLocaleString(),
    data: new Date().toString(),
  });

  return myDate.toLocaleString();
};
